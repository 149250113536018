import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

const CircleProgress = ({ percentNumber }) => (
  <div
    className="circle-progress"
    style={{
      width: 140,
      height: 140,
      fontFamily: "Ultra Condensed Roman",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
    <CircularProgressbarWithChildren
      value={percentNumber}
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)
        rotation: 0.5,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "round",

        // Text size
        textSize: "42px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        pathColor: "#67BF00",
        textColor: "#6576A0",
        trailColor: "#E3EAEC",
        backgroundColor: "#3e98c7",
      })}
    >
      <div
        className="stat__number ultra-condensed-56"
        style={{ marginTop: -10, marginRight: -8 }}
      >
        {percentNumber}
        <sup>%</sup>
      </div>
    </CircularProgressbarWithChildren>
  </div>
);

export default CircleProgress;
